import styled from 'styled-components';
import { media } from 'helpers/breakpoints';

const Wrapper = styled.div`
  min-width: 0;
`;

const PriceContainer = styled.div<{ isGrid?: boolean }>`
  display: flex;
  flex-direction: row;
  gap: ${({ theme, isGrid }) =>
    isGrid ? theme.spacing.S8 : theme.spacing.S12};
  text-wrap: nowrap;
`;

const Price = styled.div<{ enlargedPrice?: boolean; isGrid?: boolean }>`
  ${({ theme, enlargedPrice, isGrid }) =>
    enlargedPrice
      ? isGrid
        ? theme.fontSize.H24
        : theme.fontSize.H32
      : isGrid
      ? theme.fontSize.M14
      : theme.fontSize.L18};

  color: ${({ theme }) => theme.colors.GREY_DARKER};
  font-weight: ${({ theme }) => theme.fontWeight.bold};

  ${media.medium} {
    margin-bottom: 0;
  }
`;

const PricePerMonth = styled.div<{ isGrid?: boolean }>`
  ${({ theme, isGrid }) => (isGrid ? theme.fontSize.M14 : theme.fontSize.M16)};
  color: ${({ theme }) => theme.colors.GREY_DARKER};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  align-content: flex-end;
  display: ${({ isGrid }) => (isGrid ? 'none' : 'block')};
  margin-bottom: ${({ theme }) => theme.spacing.S2};
  overflow: hidden;
  text-overflow: ellipsis;

  ${media.large} {
    display: block;
  }
`;

const OldPrice = styled.span`
  ${({ theme }) => theme.fontSize.B14};
  color: ${({ theme }) => theme.colors.RED_DARK};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  height: 16px;
  position: relative;
  display: inline-block;

  &:after {
    position: absolute;
    content: '';
    left: 0;
    top: 50%;
    right: 0;
    border-top: 1px solid;
    border-color: inherit;
    transform: rotate(-12.2deg);
  }
`;

export { Wrapper, Price, PricePerMonth, PriceContainer, OldPrice };
