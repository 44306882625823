import { useEffect } from 'react';
import { DFPManager } from '@dsch/react-dfp';
import { IDFP } from 'api/types/searchPageApiTypes';
import {
  getTargetingArguments,
  refreshAdSlots,
} from 'components/Toolkit/DFP/DFP';
import { rg4js } from 'helpers/raygun';

function useDFP(settings: IDFP) {
  useEffect(() => {
    try {
      DFPManager.setTargetingArguments({
        ...getTargetingArguments(),
        ...settings,
      });
    } catch (e) {
      rg4js('send', {
        error: e,
        message: 'Error while trying to set arguments on DFP',
      });
    }
    // TODO Clean up this effect's dependencies. We're disabling this lint error for now so we can
    // clean up the lint logs. Ideally we should rewrite this code to be less error prone and trust
    // the lint rule's judgement.
    // https://distilledsch.tpondemand.com/RestUI/Board.aspx#page=userstory/98606
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function refresh() {
    DFPManager.setTargetingArguments({
      ...getTargetingArguments(),
      ...settings,
    });
    refreshAdSlots();
  }

  return {
    refresh,
  };
}

export { useDFP };
