import { Svg360OutlineIc, PlayCircleOutlineIc } from '@dsch/dd-icons';
import { useTheme } from 'styled-components';
import { useGallery } from 'components/AdCard/Gallery/Gallery.hook';
import * as Styled from 'components/AdCard/Gallery/Gallery.styled';

import type { GalleryProps } from 'components/AdCard/Gallery/Gallery.typed';

const Gallery = (props: GalleryProps) => {
  const {
    coverImage,
    thumbnails = [],
    totalMediaCount = 0,
    isGrid,
    title,
    imageAlt,
  } = props;

  const {
    displayTotalMediaCount,
    displayThumbnails,
    filteredThumbnails,
    REMAINING_PHOTO_COUNT,
  } = useGallery({ isGrid, thumbnails, totalMediaCount });

  const theme = useTheme();

  const iconMap = {
    video: (
      <PlayCircleOutlineIc width="32" height="32" color={theme.colors.WHITE} />
    ),
    '360': (
      <Svg360OutlineIc width="32" height="32" color={theme.colors.WHITE} />
    ),
  } as const;

  return (
    <Styled.GalleryWrapper>
      <Styled.CoverImage isGrid={isGrid} displayThumbnails={displayThumbnails}>
        {coverImage ? (
          <img src={coverImage} alt={imageAlt} />
        ) : (
          <Styled.FallbackIconContainer isGrid={isGrid}>
            <Styled.FallbackIcon />
          </Styled.FallbackIconContainer>
        )}

        {displayTotalMediaCount && (
          <Styled.MediaCount data-testid="media-count">
            <Styled.CameraOutlineIc
              width={16}
              height={16}
              color={theme.colors.BLACK}
            />
            {totalMediaCount}
          </Styled.MediaCount>
        )}
      </Styled.CoverImage>

      {displayThumbnails && (
        <Styled.Thumbnails isGrid={isGrid}>
          {filteredThumbnails.map((thumbnail, index) => {
            const altImage = `${title} - Image ${index + 2}`;

            return (
              <Styled.Thumbnail
                key={index}
                data-type={thumbnail.type}
                // The 360 and video types will show an overlay as well as the 3rd thumbnail in the sequence as it will
                // have an overlay of the remaining media count
                overlay={thumbnail.type !== 'photo' || index === 2}
              >
                <img
                  src={thumbnail.url ? thumbnail.url : thumbnail?.photo?.small}
                  alt={altImage}
                />
                <Styled.OverlayIcon>
                  {thumbnail.type !== 'photo' && iconMap[thumbnail.type]}
                  {/* The 3rd thumbnail in the sequence will have an overlay of the remaining media count if it is a photo */}
                  {thumbnail.type === 'photo' && index === 2 && (
                    <Styled.RemainingPhotos data-testid="media-count">
                      +{REMAINING_PHOTO_COUNT}
                    </Styled.RemainingPhotos>
                  )}
                </Styled.OverlayIcon>
              </Styled.Thumbnail>
            );
          })}
        </Styled.Thumbnails>
      )}
    </Styled.GalleryWrapper>
  );
};

export { Gallery };
