import styled from 'styled-components';

import { media } from 'helpers/breakpoints';

const SFaq = styled.div`
  background-color: ${({ theme }) => `${theme.colors.WHITE}`};
  padding: ${({ theme }) => `${theme.spacing.L40} ${theme.spacing.M16}`};
`;

const SFaqTitle = styled.h2`
  ${({ theme }) => theme.fontSize.B18};
  color: ${({ theme }) => `${theme.colors.GREY_DARKER}`};
  margin-bottom: ${({ theme }) => theme.spacing.M16};
`;
const SFaqContainer = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: ${({ theme }) => theme.spacing.M16};

  ${media.large} {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: ${({ theme }) => theme.spacing.M16};
    grid-row-gap: ${({ theme }) => theme.spacing.M24};
  }
`;

const SFaqItemQuestion = styled.p`
  ${({ theme }) => theme.fontSize.M16};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.GREY_DARKER};
  margin-bottom: ${({ theme }) => theme.spacing.S8};
`;

const SFaqItemAnswer = styled.div`
  ${({ theme }) => theme.fontSize.M16};
  color: ${({ theme }) => `${theme.colors.GREY_DARK}`};
`;

const FaqItemContainer = styled.div`
  border-left: ${({ theme }) => `1px solid ${theme.colors.GREY_LIGHT}`};
  padding-left: ${({ theme }) => theme.spacing.M16};
`;

export {
  SFaq,
  SFaqTitle,
  SFaqContainer,
  SFaqItemQuestion,
  SFaqItemAnswer,
  FaqItemContainer,
};
