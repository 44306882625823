import styled from 'styled-components';

import { StarOutlineIc } from '@dsch/dd-icons';

import { Card } from 'components/ToolkitV2/CardV2/Card';

const SavedSearchCard = styled(Card)`
  box-shadow: ${({ theme }) => theme.shadow.CONTAINER};
  border-radius: ${({ theme }) => theme.borderRadius.default};

  &:hover {
    background-color: ${({ theme }) => theme.colors.GREY_LIGHTER};
  }
`;

const SavedSearchIcon = styled(StarOutlineIc)`
  color: ${({ theme }) => theme.colors.GREY_DARKER};
`;

export { SavedSearchCard, SavedSearchIcon };
