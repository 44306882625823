import { PreviousSearchesModal } from 'features/PreviousSearches/PreviousSearchesModal/PreviousSearchesModal';

import { usePreviousSearches } from 'features/PreviousSearches/PreviousSearches.hook';

import { SkeletonBlock } from 'components/ToolkitV2/Skeleton/Skeleton';

import { WithLink } from 'components/ToolkitV2/CardV2/enhancements/WithLink/WithLink';

import {
  PreviousSearchesCard,
  PreviousSearchesSkeletonCard,
  SkeletonContainer,
  CardCopy,
  SearchIcon,
} from 'features/PreviousSearches/PreviousSearches.styled';

import {
  CARD_ORIENTATION,
  CARD_PADDING,
} from 'features/PreviousSearches/PreviousSearches.constants';

import type { PreviousSearchesArgs } from 'features/PreviousSearches/PreviousSearches.typed';

const PreviousSearches = (args: PreviousSearchesArgs) => {
  const {
    isLoading,
    isDisabled,
    onClick,
    isSavedSearchesLoading,
    savedSearches,
    askToClose,
    savedSearchesError,
    onSavedSearchesError,
    isOpen,
    login,
    status,
    onSearch,
    lastSearchData,
  } = usePreviousSearches(args);

  return (
    <>
      {isLoading && (
        <PreviousSearchesSkeletonCard
          orientationSmall={CARD_ORIENTATION}
          paddingSmall={CARD_PADDING}
          header={
            <SkeletonContainer>
              <SkeletonBlock h={24} w={24} />
              <SkeletonBlock h={24} w={212} />
            </SkeletonContainer>
          }
        />
      )}
      {!isLoading && (
        <WithLink
          isFakeLink
          handleClick={!isDisabled ? onClick : undefined}
          dataTracking="previous_searches_filters"
        >
          <PreviousSearchesCard
            disabled={isDisabled}
            header={
              <CardCopy>
                <SearchIcon />
                View your previous searches
              </CardCopy>
            }
            orientationSmall={CARD_ORIENTATION}
            paddingSmall={CARD_PADDING}
          />
        </WithLink>
      )}
      <PreviousSearchesModal
        isLoading={isSavedSearchesLoading}
        savedSearches={savedSearches}
        onClose={askToClose}
        error={savedSearchesError}
        onError={onSavedSearchesError}
        isOpen={isOpen}
        status={status}
        login={login}
        onSearch={onSearch}
        lastSearchData={lastSearchData}
      />
    </>
  );
};

export { PreviousSearches };
