import styled, { keyframes, css } from 'styled-components';
import { HeartOutlineIc } from '@dsch/dd-icons';
import { GhostButton } from 'components/Toolkit/Button/GhostButton';
import { media } from 'helpers/breakpoints';
import { TSHeartOutlineIc } from 'components/AdCard/SaveAd/SaveAd.typed';

const Action = styled(GhostButton)`
  overflow: hidden;
  border-radius: 24px;
  background-color: ${({ theme }) => theme.colors.WHITE};
  box-shadow: ${({ theme }) => theme.shadow.XLARGE};
`;

const heartAnimation = keyframes`
  33% {
    transform: scale(0.6667);
    opacity: 0.4;
    fill: #DD4045;
    stroke: #DD4045;
  }
  66% {
    transform: scale(1.3333);
  }
`;

const SHeartOutlineIc = styled(HeartOutlineIc)<TSHeartOutlineIc>`
  ${media.medium} {
    width: ${({ $iconDimensionDesktop }) => `${$iconDimensionDesktop}px`};
    height: ${({ $iconDimensionDesktop }) => `${$iconDimensionDesktop}px`};
  }

  ${({ $isAnimating, $isSaving }) =>
    $isAnimating &&
    css`
      animation: ${heartAnimation} 0.6s steps(3)
        ${$isSaving ? 'infinite' : 'forwards'};
    `}

  ${({ $isSaving, theme }) =>
    $isSaving &&
    css`
      path {
        fill: ${theme.colors.RED};
        stroke: ${theme.colors.RED};
      }
    `}
  
  ${({ $isSaving, theme, $isAdSaved, $variant }) =>
    !$isSaving &&
    css`
      path {
        fill: ${$isAdSaved
          ? theme.colors.RED
          : $variant === 'OVERLAY'
          ? `${theme.colors.BLACK}3D`
          : 'transparent'};
        stroke: ${$isAdSaved
          ? theme.colors.RED
          : $variant === 'OVERLAY'
          ? theme.colors.WHITE
          : theme.colors.GREY_DARKER};
      }
    `}
`;

export { Action, SHeartOutlineIc };
