import type { useGalleryProps } from 'components/AdCard/Gallery/Gallery.typed';
import { buildThumbnailsList } from 'components/AdCard/Gallery/Gallery.helpers';
import type { Thumbnails } from 'api/types/searchPageApiTypes';

const useGallery = (props: useGalleryProps) => {
  const { isGrid, thumbnails, totalMediaCount } = props;
  const displayThumbnails = Boolean(thumbnails?.length && !isGrid);
  const displayTotalMediaCount = !displayThumbnails && !isGrid;
  // Calculate the number of remaining photos - we will always show 3 thumbnails in the mini gallery, so the remaining
  // count will be totalMediaCount minus the 4 images already visible in the mini gallery
  const REMAINING_PHOTO_COUNT = totalMediaCount ? totalMediaCount - 4 : 0;

  let filteredThumbnails: Thumbnails[] | [] = [];
  if (thumbnails) {
    filteredThumbnails = buildThumbnailsList(thumbnails);
  }

  return {
    displayTotalMediaCount,
    displayThumbnails,
    filteredThumbnails,
    REMAINING_PHOTO_COUNT,
  };
};

export { useGallery };
