import NextLink from 'next/link';

import { SavedSearches } from 'features/PreviousSearches/PreviousSearchesModal/components/SavedSearches/SavedSearches';
import { LoadingState } from 'features/PreviousSearches/PreviousSearchesModal/components/LoadingState/LoadingState';
import { SubsidiaryText } from 'features/PreviousSearches/PreviousSearchesModal/components/SubsidiaryText/SubsidiaryText';
import { ErrorState } from 'features/PreviousSearches/PreviousSearchesModal/components/ErrorState/ErrorState';
import { Link } from 'components/Toolkit/Button/Link';
import { LastSearchCard } from 'components/PageWrappers/SharedComponents/LastSearchCard/LastSearchCard';

import {
  StyledPreviousSearchesModal,
  Content,
  Heading,
  BackButton,
  CloseIcon,
  BackIcon,
} from 'features/PreviousSearches/PreviousSearchesModal/PreviousSearchesModal.styled';

import type { ISavedSearch } from 'api/types/savedSearchApiTypes';
import type { TLastSearch } from 'components/PageWrappers/SharedComponents/LastSearchCard/types';

import { Spacer } from 'helpers/genericStyles';
import { accessibleOnClick } from 'utils/Accessibility';
import { disableScroll, enableScroll } from 'helpers/scroll';

type PreviousSearchesModalProps = {
  savedSearches?: Array<ISavedSearch>;
  isLoading: boolean;
  onClose: () => void;
  error: boolean;
  onError: () => void;
  isOpen: boolean;
  lastSearchData?: TLastSearch;
  login: () => void;
  status: 'authenticated' | 'unauthenticated' | 'loading';
  onSearch: () => void;
};

const PreviousSearchesModal = (props: PreviousSearchesModalProps) => {
  const {
    savedSearches,
    isLoading,
    onClose,
    error,
    onError,
    isOpen,
    lastSearchData,
    login,
    status,
    onSearch,
  } = props;

  return (
    <StyledPreviousSearchesModal
      isOpen={isOpen}
      onRequestClose={onClose}
      closeTimeoutMS={200}
      overlayClassName="bottom-sheet-overlay"
      onAfterOpen={disableScroll}
      onAfterClose={enableScroll}
    >
      <Content>
        <BackButton onClick={onClose}>
          <BackIcon height={16} width={16} />
          &nbsp;Back
        </BackButton>
        {!isLoading && (
          <>
            <Heading>
              View your previous searches
              <CloseIcon
                {...accessibleOnClick(onClose)}
                height={16}
                width={16}
              />
            </Heading>
            <Spacer marginBottom={['M24']} />
            {lastSearchData && (
              <LastSearchCard
                lastSearchData={lastSearchData}
                lastSearchCallback={onSearch}
                lastSearchDataTrackingValue="last_search_search_page"
                shallow
              />
            )}
            {!error && savedSearches && (
              <>
                <SavedSearches
                  savedSearches={savedSearches}
                  onClickSavedSearch={onSearch}
                />
                <Spacer marginBottom={['M24']} />
                <Link href="/dashboard/searches" NextLink={NextLink}>
                  View all saved searches
                </Link>
              </>
            )}
            {error && (
              <>
                <Spacer marginBottom={['M32']} />
                <ErrorState onError={onError} />
              </>
            )}
            {!error && !savedSearches && (
              <>
                <Spacer marginBottom={['M24']} />
                <SubsidiaryText status={status} login={login} />
              </>
            )}
          </>
        )}
        {isLoading && <LoadingState skeletonListLength={4} />}
      </Content>
    </StyledPreviousSearchesModal>
  );
};

export { PreviousSearchesModal };
