import { Spacer } from 'helpers/genericStyles';

import {
  Header,
  Body,
  RetryButton,
} from 'features/PreviousSearches/PreviousSearchesModal/components/ErrorState/ErrorState.styled';

type ErrorStateProps = { onError: () => void };

const ErrorState = (props: ErrorStateProps) => {
  const { onError } = props;

  return (
    <>
      <Header>
        <b>Something went wrong</b>
      </Header>
      <Body>
        We encountered an issue
        <br />
        loading your saved searches
      </Body>
      <Spacer marginBottom={['S8']} />
      <RetryButton
        ofType="SECONDARY"
        onClick={onError}
        colorVariant="BLUE_DARK"
      >
        Try again
      </RetryButton>
    </>
  );
};

export { ErrorState };
