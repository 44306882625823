import { useEffect, useState } from 'react';
import { useNextRouter } from 'hooks/UseNextRouter';
import { useLocationContext } from 'features/location/Location.context';
import { useFiltersState } from 'features/filters/Filters.context';
import { useURLStateManagement } from 'components/SearchPage/hooks/useURLStateManagement/useURLStateManagement';
import {
  MakeModelItem,
  MakeModelType,
  MakeOptions,
} from 'features/filters/components/MakeModel/MakeModel.typed';
import {
  getFiltersUrl,
  getUrlWithMakeModelEditable,
} from 'features/filters/Filters.helper';
import { mapMakeOptions } from 'features/filters/components/MakeModel/MakeModel.mapper';

export const useMakeModel = (
  onUpdate?: (href: string, as: string) => void,
  makeModelValue?: MakeModelItem[],
) => {
  const { updateUrlByHref } = useNextRouter();
  const handleOnUpdate = onUpdate || updateUrlByHref;

  const { selectedCounty } = useLocationContext();
  const { currentSectionQueryValue, countyTownQueryValue, radiusQueryValue } =
    useURLStateManagement();
  const validRadius = countyTownQueryValue ? radiusQueryValue : undefined;
  const county = selectedCounty?.value ?? null;
  const { filters, ranges, makeModelData, makeModel, staticParams } =
    useFiltersState();
  const [makeModelState, setMakeModelState] =
    useState<MakeModelType>(makeModel);

  const editableMakeModel = makeModelState.find(
    (makeModelItem) => makeModelItem.isEditable,
  );

  const allMakeOptions = mapMakeOptions(makeModelData);

  const makeOptions: MakeOptions[] = allMakeOptions.map((makeOption) => ({
    ...makeOption,
    isDisabled: !!makeModelState.find(
      (makeModelItem) => makeModelItem.makeValue === makeOption.displayName,
    ),
  }));

  const hasAllMakeSelected =
    makeModelState.filter((item) => item.makeValue.length > 0).length === 3 &&
    makeModelState.filter((item) => !item.isEditable).length === 3;

  useEffect(() => {
    const propState = JSON.stringify(makeModelValue);
    const currentState = JSON.stringify(makeModelState);
    if (
      propState !== currentState &&
      makeModelValue &&
      makeModelValue.length > 0
    ) {
      setMakeModelState(makeModelValue);
    }
    // TODO Clean up this effect's dependencies. We're disabling this lint error for now so we can
    // clean up the lint logs. Ideally we should rewrite this code to be less error prone and trust
    // the lint rule's judgement.
    // https://distilledsch.tpondemand.com/RestUI/Board.aspx#page=userstory/98606
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(makeModelValue)]);

  useEffect(() => {
    const propState = JSON.stringify(makeModel);
    const currentState = JSON.stringify(makeModelState);
    if (propState !== currentState) {
      setMakeModelState(makeModel);
    }
    // TODO Clean up this effect's dependencies. We're disabling this lint error for now so we can
    // clean up the lint logs. Ideally we should rewrite this code to be less error prone and trust
    // the lint rule's judgement.
    // https://distilledsch.tpondemand.com/RestUI/Board.aspx#page=userstory/98606
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(makeModel)]);

  const onMakeChange = (id: string, makeValue: string) => {
    const newMakeModel = makeModelState.map((param) =>
      param.id === id
        ? {
            ...{ makeValue: param.makeValue, modelValue: [], isVisible: false },
            ...{ id, makeValue, isEditable: param.isEditable },
          }
        : param,
    );
    const url = getFiltersUrl(
      currentSectionQueryValue,
      staticParams,
      filters,
      newMakeModel,
      ranges,
      countyTownQueryValue,
      validRadius,
      county,
    );
    const updatedUrlAs = getUrlWithMakeModelEditable(url, id);
    handleOnUpdate(updatedUrlAs, url);
  };

  const onRemove = (id: string) => {
    const selectedMakeModel: MakeModelItem[] = makeModelState.filter(
      (item) => item.id !== id && item.makeValue !== '',
    );

    const newResult = selectedMakeModel.map((item, index) => ({
      id: `make_model_${index}`,
      makeValue: item.makeValue || '',
      modelValue: item.modelValue || [],
      isEditable: item.isEditable || false,
      isVisible: false,
    }));

    id =
      newResult.length > 0
        ? newResult[newResult.length - 1].id.replace(/(\d+)$/, (match) => {
            const increment = makeModelState.some(
              (item) => item.isEditable && item.makeValue !== '',
            )
              ? 0
              : 1;
            return String(Number(match) + increment);
          })
        : id;

    const url = getFiltersUrl(
      currentSectionQueryValue,
      staticParams,
      filters,
      selectedMakeModel,
      ranges,
      countyTownQueryValue,
      validRadius,
      county,
    );

    const updatedUrlAs = getUrlWithMakeModelEditable(url, id);
    handleOnUpdate(updatedUrlAs, url);
  };

  const onAddModel = (id: string, modelValue: string) => {
    if (modelValue === '') {
      onClear(id);
    } else {
      const newMakeModel = makeModelState.map((item) => {
        if (item.id === id) {
          const uniqueValues = Array.from(
            new Set([...item.modelValue, modelValue]),
          );
          return { ...item, modelValue: uniqueValues };
        }
        return item;
      });
      const url = getFiltersUrl(
        currentSectionQueryValue,
        staticParams,
        filters,
        newMakeModel,
        ranges,
        countyTownQueryValue,
        validRadius,
        county,
      );

      const updatedUrlAs = getUrlWithMakeModelEditable(url, id);
      handleOnUpdate(updatedUrlAs, url);
    }
  };

  const onRemoveModel = (id: string, modelValue: string) => {
    const newMakeModel = makeModelState.map((item) => {
      if (item.id === id) {
        const updatedModelValue = item.modelValue.filter(
          (val) => val !== modelValue,
        );
        return { ...item, modelValue: updatedModelValue };
      }
      return item;
    });
    const url = getFiltersUrl(
      currentSectionQueryValue,
      staticParams,
      filters,
      newMakeModel,
      ranges,
      countyTownQueryValue,
      validRadius,
      county,
    );
    const updatedUrlAs = getUrlWithMakeModelEditable(url, id);
    handleOnUpdate(updatedUrlAs, url);
  };

  const onClear = (id: string) => {
    const newMakeModel = makeModelState.map((item) => {
      if (item.id === id) {
        return { ...item, modelValue: [] };
      }
      return item;
    });
    const url = getFiltersUrl(
      currentSectionQueryValue,
      staticParams,
      filters,
      newMakeModel,
      ranges,
      countyTownQueryValue,
      validRadius,
      county,
    );

    const updatedUrlAs = getUrlWithMakeModelEditable(url, id);
    handleOnUpdate(updatedUrlAs, url);
  };

  const onAddAnother = () => {
    if (editableMakeModel) {
      const visibleMakeModel = makeModelState.filter(
        (makeModelItem) =>
          !makeModelItem.isVisible && makeModelItem.id !== editableMakeModel.id,
      );

      if (visibleMakeModel.length > 0) {
        const newMakeModel: MakeModelItem[] = makeModelState.filter(
          (item) => item.makeValue !== '',
        );

        const url = getFiltersUrl(
          currentSectionQueryValue,
          staticParams,
          filters,
          newMakeModel,
          ranges,
          countyTownQueryValue,
          validRadius,
          county,
        );
        const updatedUrlAs = getUrlWithMakeModelEditable(
          url,
          visibleMakeModel[0].id,
        );
        handleOnUpdate(updatedUrlAs, url);
      } else {
        const newMakeModel: MakeModelItem[] = makeModelState.filter(
          (item) => item.makeValue !== '',
        );
        const url = getFiltersUrl(
          currentSectionQueryValue,
          staticParams,
          filters,
          newMakeModel,
          ranges,
          null,
          '',
        );
        handleOnUpdate(url, url);
      }
    }
  };

  return {
    onClear,
    onAddAnother,
    onAddModel,
    onMakeChange,
    onRemoveModel,
    onRemove,
    makeModelState,
    makeOptions,
    makeModelData,
    editableMakeModel,
    currentSectionQueryValue,
    hasAllMakeSelected,
  };
};
