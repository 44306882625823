import {
  Container,
  Header,
  HeaderText,
  CloseIcon,
  Copy,
  TooltipContainer,
  TooltipBody,
  Arrow,
} from 'components/ToolkitV2/Tooltip/Tooltip.styled';

import { POSITION } from 'components/ToolkitV2/Tooltip/Tooltip.typed';
import type { TooltipProps } from 'components/ToolkitV2/Tooltip/Tooltip.typed';

import { accessibleOnClick } from 'utils/Accessibility';

const Tooltip = (props: TooltipProps) => {
  const {
    isOpen,
    children,
    headerText,
    copy,
    askToClose,
    position = POSITION.ABOVE,
    className,
    dataTrackingAttribute,
  } = props;

  return (
    <Container className={className}>
      {isOpen && (
        <TooltipContainer
          position={position}
          data-tracking={dataTrackingAttribute}
        >
          <TooltipBody>
            <Header>
              <HeaderText>{headerText}</HeaderText>
              {askToClose && (
                <CloseIcon
                  {...accessibleOnClick(
                    askToClose,
                    'button',
                    0,
                    'close-button',
                  )}
                  height={16}
                  width={16}
                />
              )}
            </Header>
            <Copy>{copy}</Copy>
            <Arrow position={position} />
          </TooltipBody>
        </TooltipContainer>
      )}
      {children}
    </Container>
  );
};

export { Tooltip };
