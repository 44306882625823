import styled from 'styled-components';

import { LinkButton } from 'components/Toolkit/Button/LinkButton';

const Header = styled.p`
  ${({ theme }) => theme.fontSize.M16};
  color: ${({ theme }) => theme.colors.GREY_DARKER};
  text-align: center;
`;

const Body = styled.p`
  ${({ theme }) => theme.fontSize.M14};
  color: ${({ theme }) => theme.colors.GREY_DARK};
  text-align: center;
`;

const RetryButton = styled(LinkButton)`
  margin: 0 auto;
`;

export { Header, Body, RetryButton };
