import { Spacer } from 'helpers/genericStyles';

import {
  PreviousSearchesSkeletonCard,
  SkeletonContainer,
} from 'features/PreviousSearches/PreviousSearches.styled';

import { SkeletonBlock } from 'components/ToolkitV2/Skeleton/Skeleton';
import {
  CARD_ORIENTATION,
  CARD_PADDING,
} from 'features/PreviousSearches/PreviousSearches.constants';

type LoadingStateProps = { skeletonListLength: number };

const LoadingState = (props: LoadingStateProps) => {
  const { skeletonListLength } = props;

  return (
    <>
      <SkeletonBlock h={24} w={264} />
      <Spacer marginBottom={['M24']} />
      {Array(skeletonListLength)
        .fill(null)
        .map((_item: null, index: number) => {
          return (
            <>
              <Spacer marginBottom={['M16']} />
              <PreviousSearchesSkeletonCard
                orientationSmall={CARD_ORIENTATION}
                key={`previous-searches-skeleton-card-${index}`}
                paddingSmall={CARD_PADDING}
                header={
                  <SkeletonContainer>
                    <SkeletonBlock h={24} w={24} />
                    <SkeletonBlock h={32} w={212} />
                  </SkeletonContainer>
                }
              />
            </>
          );
        })}
      <Spacer marginBottom={['M24']} />
      <SkeletonBlock h={24} w={264} />
    </>
  );
};

export { LoadingState };
