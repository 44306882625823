import type { TagsListProps } from 'components/AdCard/TagsList/TagsList.typed';
import { GreenLightTag } from 'components/Toolkit/Tags/PreBuilt/GreenlightTag';
import { Tag } from 'components/Toolkit/Tags/Tag';
import * as Styled from 'components/AdCard/TagsList/TagsList.styled';

const TagsList = ({ tags, className }: TagsListProps) => (
  <Styled.Container className={className}>
    {tags?.map(({ displayName, type }) => {
      if (type === 'greenlight') {
        return <GreenLightTag key={displayName} />;
      }
      return (
        <Tag key={displayName} text={displayName} textColor={'GREY_DARK'} />
      );
    })}
  </Styled.Container>
);

export { TagsList };
