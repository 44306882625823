import { useCallback } from 'react';

import { LinkButton } from 'components/Toolkit/Button/LinkButton';

import { useFiltersDispatch } from 'features/filters/Filters.context';

import {
  Header,
  Heading,
} from 'features/filters/components/FiltersPanel/FiltersPanel.styled';

import type { FiltersPanelProps } from 'features/filters/components/FiltersPanel/FiltersPanel.typed';
import { PreviousSearches } from 'features/PreviousSearches/PreviousSearches';
import { Spacer } from 'helpers/genericStyles';
import { PAGE } from 'helpers/pages';

const FiltersPanel = (props: FiltersPanelProps) => {
  const { children, className } = props;

  const { reset, setIsFilterModalOpen } = useFiltersDispatch();

  // TODO: Clean up this callback's dependencies. We're disabling this lint error for now so we can
  // clean up the lint logs.
  // https://distilledsch.tpondemand.com/RestUI/Board.aspx#page=userstory/98606
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearchCallback = useCallback(() => setIsFilterModalOpen(false), []);

  return (
    <div className={className}>
      <Header>
        <Heading>Filters</Heading>
        <LinkButton onClick={reset}>Reset All</LinkButton>
      </Header>
      <Spacer marginBottom={['M16']} />
      <PreviousSearches
        onSearchCallback={onSearchCallback}
        page={PAGE.SEARCH}
      />
      <Spacer marginBottom={['M16', 'S8']} />
      {children}
    </div>
  );
};

export { FiltersPanel };
