import { useFooter } from 'components/SearchPage/components/Footer/Footer.hook';

import {
  StyledStickyFooter,
  StickyFooterSavedSearchButton,
  SavedSearchTooltip,
  SaveIcon,
  Container,
} from 'components/SearchPage/components/Footer/Footer.styled';

type FooterProps = {
  isSavedSearchDisabled: boolean;
  handleOnClickSave: () => Promise<void>;
  isSavedSearchPromptOpen: boolean;
  closeSavedSearchPrompt: () => void;
};

const Footer = (props: FooterProps) => {
  const {
    isSavedSearchDisabled,
    handleOnClickSave,
    isSavedSearchPromptOpen,
    closeSavedSearchPrompt,
  } = props;

  const { displaySaveSearch } = useFooter();

  const onClickSave = () => {
    handleOnClickSave();
    isSavedSearchPromptOpen && closeSavedSearchPrompt();
  };

  return (
    <StyledStickyFooter data-testid="search-sticky-footer">
      {!isSavedSearchDisabled && (
        <Container active={displaySaveSearch || isSavedSearchPromptOpen}>
          <SavedSearchTooltip
            isOpen={isSavedSearchPromptOpen}
            askToClose={closeSavedSearchPrompt}
            headerText="Be the first to know"
            copy="Save this search and we'll notify you when new ads match your selection"
            dataTrackingAttribute="save-search-prompt"
          >
            <StickyFooterSavedSearchButton
              onClick={onClickSave}
              data-testid="save-search-button"
              icon={<SaveIcon height={20} width={20} />}
            >
              Save Search
            </StickyFooterSavedSearchButton>
          </SavedSearchTooltip>
        </Container>
      )}
    </StyledStickyFooter>
  );
};

export { Footer };
