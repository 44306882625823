import { useEffect, useState } from 'react';
import { useScript } from 'hooks/UseScript';
import type { DependencyList } from 'react';
import { IAdSenseUnit, AD_SENSE_TYPES } from 'types';
import { IAdSense } from 'api/types/searchPageApiTypes';
import { rg4js } from 'helpers/raygun';

export interface IUseAdSenseProps {
  options: IAdSense;
  units: IAdSenseUnit[];
  dependencies: DependencyList;
  adType?: AD_SENSE_TYPES;
  onLoadContainer?: (container: string, success: boolean) => void;
}

function useAdSense({
  units,
  options,
  dependencies,
  adType = AD_SENSE_TYPES.SEARCH,
  onLoadContainer,
}: IUseAdSenseProps) {
  const [isReady, setIsReady] = useState(false);

  function load() {
    try {
      if (isReady && dependencies.some((item) => item)) {
        (window as any)._googCsa &&
          (window as any)._googCsa(
            adType,
            {
              adsafe: 'high',
              linkTarget: '_blank',
              ...options,
            },
            ...units.map((unit) => ({
              ...unit,
              adLoadedCallback: unit.adLoadedCallback || onLoadContainer,
            })),
          );
      }
    } catch (e) {
      rg4js('send', {
        error: e,
        message: 'Error loading Google Custom Search Ads',
        customData: {
          isReady,
          dependencies,
          units,
        },
      });
    }
  }

  function refresh() {
    load();
  }

  useEffect(() => {
    if (isReady) {
      load();
    }
    // TODO Clean up this effect's dependencies. We're disabling this lint error for now so we can
    // clean up the lint logs. Ideally we should rewrite this code to be less error prone and trust
    // the lint rule's judgement.
    // https://distilledsch.tpondemand.com/RestUI/Board.aspx#page=userstory/98606
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady, ...dependencies]);

  useScript(
    'https://www.google.com/adsense/search/ads.js',
    'adSense',
    dependencies,
    () => setIsReady(true),
    true,
  );

  return { refresh, isReady };
}

export { useAdSense };
