import styled from 'styled-components';

import { media } from 'helpers/breakpoints';

const Header = styled.div`
  padding-bottom: ${({ theme }) => theme.spacing.S8};
  margin-bottom: ${({ theme }) => theme.spacing.M16};
  border-bottom: 1px solid ${({ theme }) => theme.colors.GREY_LIGHT};

  ${media.large} {
    display: flex;
    justify-content: space-between;
    padding-bottom: ${({ theme }) => theme.spacing.M16};
  }
`;

const Heading = styled.h5`
  ${({ theme }) => theme.fontSize.L24};
`;

export { Header, Heading };
