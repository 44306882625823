import { useEffect } from 'react';
import { pushToDataLayer } from 'services/gtmService';
import { getPlatform } from 'helpers/getPlatform';

function useWootric(
  userId: number | undefined,
  category: string,
  userRegistrationDate: string,
  enabled?: boolean,
) {
  useEffect(() => {
    if (userId && enabled) {
      pushToDataLayer({
        userId,
        created_at: Math.round(
          Date.parse(userRegistrationDate.replace('+', 'T')) / 1000,
        ),
        category,
        platform: getPlatform(),
      });
    }
    // TODO: Clean up this effect's dependencies. We're disabling this lint error for now so we can
    // clean up the lint logs. Ideally we should rewrite this code to be less error prone and trust
    // the lint rule's judgement.
    // https://distilledsch.tpondemand.com/RestUI/Board.aspx#page=userstory/98606
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, enabled]);

  return;
}

export { useWootric };
