import styled from 'styled-components';

import { TimeRewindOutlineIc } from '@dsch/dd-icons';

import { Card } from 'components/ToolkitV2/CardV2/Card';

const SearchIcon = styled(TimeRewindOutlineIc)``;

const CardCopy = styled.p`
  ${({ theme }) => theme.fontSize.M16}
  padding: ${({ theme }) => theme.spacing.S8} 0;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.S8};
`;

const PreviousSearchesCard = styled(Card)<{ disabled: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  background-color: ${({ disabled, theme }) =>
    disabled ? theme.colors.GREY_LIGHTER : theme.colors.WHITE};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  box-shadow: ${({ theme }) => theme.shadow.CONTAINER};
  font-weight: ${({ theme }) => theme.fontWeight.bold};

  &:hover {
    background-color: ${({ theme }) => theme.colors.GREY_LIGHTER};
  }

  ${CardCopy} {
    color: ${({ disabled, theme }) =>
      disabled ? theme.colors.GREY_DARK : theme.colors.GREY_DARKER};
  }

  ${SearchIcon} {
    color: ${({ disabled, theme }) =>
      disabled ? theme.colors.GREY_LIGHT : theme.colors.GREY_DARKER};
  }
`;

const PreviousSearchesSkeletonCard = styled(Card)`
  cursor: not-allowed;
  border-radius: ${({ theme }) => theme.borderRadius.default};
  box-shadow: ${({ theme }) => theme.shadow.CONTAINER};
`;

const SkeletonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.M16};
  padding: ${({ theme }) => theme.spacing.S8} 0;
`;

export {
  PreviousSearchesCard,
  PreviousSearchesSkeletonCard,
  SkeletonContainer,
  CardCopy,
  SearchIcon,
};
