/*
    There currently is a Toolkit V2 Price component. It's highly used throughout the site at the moment and differents vastly from the new UI.
    When all cards are migrated, consider using this card as the Toolkit V2 Price component.
*/

import * as Styled from 'components/AdCard/Price/Price.styled';
import type { PriceProps } from 'components/AdCard/Price/Price.typed';
import { NO_PRICE_TEXT, PRICE_ON_REQUEST_TEXT } from 'helpers/constants';

const Price = (props: PriceProps) => {
  const { financePPM, previousPrice, price, priceOnRequest, isGrid } = props;

  const priceText = priceOnRequest
    ? PRICE_ON_REQUEST_TEXT
    : price ?? NO_PRICE_TEXT;
  const enlargedPrice = !priceOnRequest && price && price !== '€0';

  return (
    <Styled.Wrapper>
      {previousPrice && <Styled.OldPrice>{previousPrice}</Styled.OldPrice>}

      <Styled.PriceContainer isGrid={isGrid}>
        <Styled.Price enlargedPrice={!!enlargedPrice} isGrid={isGrid}>
          {priceText}
        </Styled.Price>

        {financePPM && (
          <Styled.PricePerMonth isGrid={isGrid}>
            {financePPM}
          </Styled.PricePerMonth>
        )}
      </Styled.PriceContainer>
    </Styled.Wrapper>
  );
};

export default Price;
