import styled from 'styled-components';
import { ThemeTypes } from 'types/Theme.namespace';

const Highlight = styled.div<{ color: ThemeTypes.Colors }>`
  position: relative;
  z-index: 10;
  height: 0;

  &:before {
    position: relative;
    display: block;
    content: '';
    border-top: ${({ theme, color }) =>
      `${theme.spacing.S2} solid ${theme.colors[color]}`};
  }
`;

const Badge = styled.div<{ color: ThemeTypes.Colors }>`
  ${({ theme }) => theme.fontSize.M14}
  color: ${({ theme }) => theme.colors.WHITE};
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${({ theme, color }) => theme.colors[color]};
  padding: ${({ theme }) => `${theme.spacing.S2} ${theme.spacing.S8}`};
  border-bottom-right-radius: ${({ theme }) => theme.borderRadius.default};
`;

export { Highlight, Badge };
