import { media } from 'helpers/breakpoints';
import styled from 'styled-components';
import { CameraOutlineIc as CameraOutlineIcBase } from '@dsch/dd-icons';

const MEDIA_WIDTH = 360;
const MEDIA_HEIGHT = 270;
const MEDIA_ASPECT_RATIO = `${MEDIA_WIDTH} / ${MEDIA_HEIGHT}`;

const GalleryWrapper = styled.div`
  max-width: 100%;
  width: 100%;
  height: auto;

  ${media.medium} {
    max-width: ${MEDIA_WIDTH}px;
  }
`;

const CoverImage = styled.div<{
  isGrid?: boolean;
  displayThumbnails: boolean;
}>`
  width: 100%;
  height: auto;
  position: relative;
  margin-bottom: ${({ theme, displayThumbnails }) =>
    displayThumbnails ? theme.spacing.S4 : '0'};
  aspect-ratio: ${MEDIA_ASPECT_RATIO};

  ${media.medium} {
    width: ${({ isGrid }) => (isGrid ? '100%' : MEDIA_WIDTH + 'px')};
  }

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
`;

const Thumbnails = styled.div<{ isGrid?: boolean }>`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${({ theme }) => theme.spacing.S4};

  ${media.medium} {
    height: ${({ isGrid }) => (isGrid ? 'auto' : '88px')};
  }
`;

const Thumbnail = styled.div<{ overlay?: boolean }>`
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    aspect-ratio: ${MEDIA_ASPECT_RATIO};
  }

  ${({ overlay, theme }) =>
    overlay &&
    `
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 1;
      background-color: ${theme.overlay.LIGHT};
    }
  `}
`;

const OverlayIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  color: ${({ theme }) => theme.colors.WHITE};
`;

const RemainingPhotos = styled.div`
  ${({ theme }) => theme.fontSize.L18};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.WHITE};
`;

const MediaCount = styled.div`
  ${({ theme }) => theme.fontSize.B12};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  background-color: ${({ theme }) => theme.colors.WHITE};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  box-shadow: ${({ theme }) => theme.getOuterShadow(theme.colors.GREY_DARK)};
  color: ${({ theme }) => theme.colors.GREY_DARKER};
  padding: ${({ theme }) => `${theme.spacing.S4} ${theme.spacing.S8}`};

  position: absolute;
  bottom: ${({ theme }) => theme.spacing.M16};
  left: ${({ theme }) => theme.spacing.M16};
`;

const CameraOutlineIc = styled(CameraOutlineIcBase)`
  vertical-align: text-bottom;
  margin-right: ${({ theme }) => theme.spacing.S4};
`;

const FallbackIconContainer = styled.div<{ isGrid?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.GREY_LIGHTER};
  width: 100%;
  aspect-ratio: ${MEDIA_WIDTH} / ${MEDIA_HEIGHT};

  ${media.medium} {
    width: ${({ isGrid }) => (isGrid ? '100%' : MEDIA_WIDTH + 'px')};
  }
`;

const FallbackIcon = styled(CameraOutlineIc)`
  width: ${({ theme }) => theme.spacing.L48};
  height: ${({ theme }) => theme.spacing.L48};
  color: ${({ theme }) => theme.colors.GREY};
`;

export {
  GalleryWrapper,
  CoverImage,
  Thumbnails,
  Thumbnail,
  RemainingPhotos,
  MediaCount,
  FallbackIcon,
  FallbackIconContainer,
  OverlayIcon,
  CameraOutlineIc,
};
