import styled from 'styled-components';
import { ShieldCheckmark2Ic, StarIc } from '@dsch/dd-icons';
import { media } from 'helpers/breakpoints';

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.fontSize.M14};
  color: ${({ theme }) => theme.colors.GREY_DARKER};
  /* Allow this div to collapse to support truncation within flex children */
  min-width: 0;
`;

export const Title = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const SubTitle = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.S4};
  color: ${({ theme }) => theme.colors.GREY_DARK};
`;

export const Container = styled.div<{ isGrid?: boolean }>`
  ${({ theme }) => theme.fontSize.M16};
  display: flex;
  gap: ${({ theme }) => theme.spacing.S12};
  padding: ${({ theme, isGrid }) =>
    `${isGrid ? theme.spacing.S12 : theme.spacing.S8} ${theme.spacing.M16}`};
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.GREY_LIGHT}`};

  ${media.medium} {
    padding: ${({ theme }) => `${theme.spacing.S12} ${theme.spacing.M24}`};
  }
`;

export const Description = styled.div`
  ${({ theme }) => theme.fontSize.M14};
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.S4};
  color: ${({ theme }) => theme.colors.GREY_DARK};
`;

export const Logo = styled.img`
  height: ${({ theme }) => theme.spacing.L48};
  width: ${({ theme }) => theme.spacing.L48};
  min-width: ${({ theme }) => theme.spacing.L48};
`;

export const TrustedDealerBadge = styled(ShieldCheckmark2Ic)`
  color: ${({ theme }) => theme.colors.GREEN};
  height: ${({ theme }) => theme.spacing.M16};
  width: ${({ theme }) => theme.spacing.M16};
  min-width: ${({ theme }) => theme.spacing.M16};
`;

export const Star = styled(StarIc)`
  color: #ff9700;
  height: ${({ theme }) => theme.spacing.M16};
  width: ${({ theme }) => theme.spacing.M16};
  min-width: ${({ theme }) => theme.spacing.M16};
`;

export const DealerType = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
