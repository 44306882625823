import styled from 'styled-components';
import { media } from 'helpers/breakpoints';
import { LineClamp } from 'components/Toolkit/LineClamp/LineClamp';
import { LineClampProps } from 'components/Toolkit/LineClamp/LineClamp.typed';
import { ResponsiveFontSize } from 'components/SearchPage/components/Listings/components/SearchCardV2/SearchCardV2.typed';
import { TagsList as BaseTagsList } from 'components/AdCard/TagsList/TagsList';

const Wrapper = styled.div<{ isGrid: boolean }>`
  background-color: ${({ theme }) => theme.colors.WHITE};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  box-shadow: ${({ theme }) => theme.shadow.LARGE};
  overflow: hidden;

  ${({ isGrid }) =>
    isGrid &&
    `
    height: 100%;
    `}
`;

const BodySavedAd = styled.div<{ isGrid: boolean }>`
  display: none;

  ${media.medium} {
    display: ${({ isGrid }) => (isGrid ? 'none' : 'block')};
  }

  button {
    padding: ${({ theme }) => theme.spacing.S12};
  }
`;

const ImageSavedAd = styled.div<{ isGrid: boolean }>`
  display: ${({ isGrid }) => (isGrid ? 'none' : 'block')};

  ${media.medium} {
    display: ${({ isGrid }) => (isGrid ? 'block' : 'none')};
  }

  button {
    padding: ${({ theme }) => theme.spacing.S12};
  }
`;

const TagsList = styled(BaseTagsList)<{ singleLine: boolean }>`
  overflow-x: auto;
  flex-wrap: nowrap;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none; /* Safari/Webkit */
  }
  ${media.medium} {
    flex-wrap: ${({ singleLine }) => (singleLine ? 'nowrap' : 'wrap')};
  }

  * {
    white-space: nowrap;
  }
`;
const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.M16};

  ${media.medium} {
    gap: ${({ theme }) => theme.spacing.M24};
  }
`;

const Header = styled.div<{ isGrid: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme, isGrid }) => (isGrid ? theme.spacing.S4 : theme.spacing.S8)};
`;

const Title = styled(LineClamp)<
  LineClampProps &
    ResponsiveFontSize & {
      isGrid: boolean;
    }
>`
  ${({ theme, fontSizes }) => fontSizes.sm && theme.fontSize[fontSizes.sm]};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.GREY_DARKER};
  ${media.medium} {
    ${({ isGrid }) => `
        @supports (-webkit-line-clamp: ${isGrid ? 2 : 1}) {
          -webkit-line-clamp:${isGrid ? 2 : 1};
        }
      `};
  }
`;

const MetaInfo = styled(LineClamp)`
  color: ${({ theme }) => theme.colors.GREY_DARK};
`;

const MetaInfoItem = styled.li<ResponsiveFontSize>`
  ${({ theme, fontSizes }) => fontSizes.sm && theme.fontSize[fontSizes.sm]};
  list-style: none;
  display: inline;
  align-items: center;
  color: ${({ theme }) => theme.colors.GREY_DARK};

  &:after {
    ${({ theme }) => theme.fontSize.M14}
    margin: 0 ${({ theme }) => theme.spacing.S8};
    content: '·';
  }

  &:last-child {
    &:after {
      display: none;
    }
  }
`;

export {
  Wrapper,
  BodySavedAd,
  ImageSavedAd,
  TagsList,
  HeaderContainer,
  Title,
  MetaInfo,
  MetaInfoItem,
  Header,
};
