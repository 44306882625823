import { useFiltersState } from 'features/filters/Filters.context';
import { useEffect, useState } from 'react';
import { MakeModelItem } from 'features/filters/components/MakeModel/MakeModel.typed';
import { useOnUpdateOnly } from 'hooks/UseOnUpdateOnly';
import { useQuickFilterContext } from 'components/SearchPage/features/QuickFilters/QuickFilters.context';

const useMakeModel = () => {
  const { makeModel } = useFiltersState();
  const [makeModelState, setMakeModelState] = useState<MakeModelItem[]>([]);
  const { isFilterBottomSheetOpen } = useQuickFilterContext();

  const compareMakeModel = (
    makeModelA: MakeModelItem[],
    makeModelB: MakeModelItem[],
  ): boolean => {
    if (makeModelA.length !== makeModelB.length) {
      return false;
    }

    const sortById = (a: MakeModelItem, b: MakeModelItem) =>
      a.id.localeCompare(b.id);
    const sortedMakeModelA = makeModelA.slice().sort(sortById);
    const sortedMakeModelB = makeModelB.slice().sort(sortById);
    return sortedMakeModelA.every(
      (item, index) =>
        JSON.stringify(item) === JSON.stringify(sortedMakeModelB[index]),
    );
  };

  useEffect(() => {
    const propState = JSON.stringify(makeModel);
    const currentState = JSON.stringify(makeModelState);
    if (propState !== currentState) {
      setMakeModelState(makeModel);
    }
    // TODO Clean up this effect's dependencies. We're disabling this lint error for now so we can
    // clean up the lint logs. Ideally we should rewrite this code to be less error prone and trust
    // the lint rule's judgement.
    // https://distilledsch.tpondemand.com/RestUI/Board.aspx#page=userstory/98606
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(makeModel)]);

  useOnUpdateOnly(() => {
    if (isFilterBottomSheetOpen) {
      if (!compareMakeModel(makeModel, makeModelState)) {
        setMakeModelState(makeModel);
      }
    }
  }, [isFilterBottomSheetOpen]);

  return {
    makeModelState,
    setMakeModelState,
  };
};

export { useMakeModel };
