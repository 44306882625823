import type { Session } from 'next-auth/core/types';

const mockSession: Session = {
  user: {
    name: 'cyprivate',
    email: 'cyprivate.donedeal@gmail.com',
    image: '',
  },
  expires: '3000-01-01T00:00:00.191Z',
  accessToken: 'abc',
  idToken: 'xyz',
  expiresAt: 2082758400,
};

export { mockSession };
