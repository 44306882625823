import { Button } from 'components/Toolkit/Button/Button';
import { Tooltip } from 'components/ToolkitV2/Tooltip/Tooltip';

import { SaveIcon } from 'components/SearchPage/components/SavedSearchButton/SavedSearchButton.styled';

import type { SavedSearchButtonProps } from 'components/SearchPage/components/SavedSearchButton/SavedSearchButton.typed';

import { POSITION } from 'components/ToolkitV2/Tooltip/Tooltip.typed';

const SavedSearchButton = (props: SavedSearchButtonProps) => {
  const {
    disabled,
    onClick,
    className,
    isSavedSearchPromptOpen,
    closeSavedSearchPrompt,
  } = props;

  const onClickSave = () => {
    onClick();
    isSavedSearchPromptOpen && closeSavedSearchPrompt();
  };

  return (
    <Tooltip
      isOpen={isSavedSearchPromptOpen}
      askToClose={closeSavedSearchPrompt}
      headerText="Be the first to know"
      copy="Save this search and we'll notify you when new ads match your selection"
      position={POSITION.BELOW}
      dataTrackingAttribute="save-search-prompt"
    >
      <Button
        data-testid="save-search-button"
        disabled={disabled}
        icon={<SaveIcon disabled={disabled} height={20} width={20} />}
        onClick={onClickSave}
        className={className}
      >
        Save Search
      </Button>
    </Tooltip>
  );
};

export { SavedSearchButton };
