import { InfoTitle } from 'components/Toolkit/InfoTitle/InfoTitle';
import { LineClamp } from 'components/Toolkit/LineClamp/LineClamp';
import { WithLink } from 'components/ToolkitV2/CardV2/enhancements/WithLink/WithLink';

import {
  CARD_ORIENTATION,
  CARD_PADDING,
} from 'features/PreviousSearches/PreviousSearches.constants';

import {
  SavedSearchCard,
  SavedSearchIcon,
} from 'features/PreviousSearches/PreviousSearchesModal/components/SavedSearches/SavedSearches.styled';

import { Spacer } from 'helpers/genericStyles';
import { getSavedSearchUrl } from 'components/Dashboard/pages/SavedSearches/helpers/url';
import { accessibleOnClick } from 'utils/Accessibility';

import type { ISavedSearch } from 'api/types/savedSearchApiTypes';

type SavedSearchesProps = {
  savedSearches: Array<ISavedSearch>;
  onClickSavedSearch: () => void;
};

const SavedSearches = (props: SavedSearchesProps) => {
  const { savedSearches, onClickSavedSearch } = props;

  return (
    <ul>
      {savedSearches.map((savedSearch) => {
        const href = getSavedSearchUrl(savedSearch);

        return (
          <>
            <Spacer marginBottom={['M16']} />
            <li
              key={savedSearch.id}
              {...accessibleOnClick(onClickSavedSearch)}
              data-tracking="saved_search_card"
            >
              <WithLink href={href} shallow>
                <SavedSearchCard
                  header={
                    <InfoTitle
                      title={savedSearch.name}
                      listItems={[
                        <LineClamp key={savedSearch.id} lines={1}>
                          {savedSearch.description}
                        </LineClamp>,
                      ]}
                      icon={<SavedSearchIcon height={24} width={24} />}
                      variant="XS"
                    />
                  }
                  paddingSmall={CARD_PADDING}
                  orientationSmall={CARD_ORIENTATION}
                />
              </WithLink>
            </li>
          </>
        );
      })}
    </ul>
  );
};

export { SavedSearches };
