import { useCallback, useMemo, useState } from 'react';

import { useRouter } from 'next/router';

import { useFiltersState } from 'features/filters/Filters.context';

const useFilterCount = () => {
  const { query } = useRouter();
  const { filtersData } = useFiltersState();

  const calculateCount = useCallback(() => {
    const { section, ...rest } = query;
    const [_currentSection, make, _model, filterValue] = Array.isArray(section)
      ? section
      : [];
    const { area, make: makeQueryValue } = rest;

    let count = 0;

    const appliedFilters = Object.keys(rest);

    /*
     * Note that while the keyword search value may appear as a query and is part of the filters data,
     * we do not include it in the count
     *
     * Also note that for the price per month variant of the price filter, the query ("pricePerMonth_from" and "pricePerMonth_to"),
     * do not match the name of the filter ("price").
     *
     * However, we update "filtersData" with a hidden filters including one called "carFinance". This name of this filter corresponds
     * to a query value that is applied when "pricePerMonth_from" and or "pricePerMonth_to" is added, allowing us to recognise when to
     * update the count.
     */
    if (appliedFilters.length > 0) {
      filtersData.forEach(({ name, searchQueryGroup }) => {
        const includesFilter =
          searchQueryGroup === 'filters' && appliedFilters.includes(name);
        const includesRange =
          (searchQueryGroup === 'ranges' &&
            appliedFilters.includes(`${name}_from`)) ||
          appliedFilters.includes(`${name}_to`);
        /*
         * When applying GBP to price, the query does not follow the pattern of other range filters,
         * whereby the name of the range filter is appended with "_from" or "_to". We must therefore
         * peform a specific check to see if it has been applied.
         */
        const includesPoundSterling =
          searchQueryGroup === 'ranges' &&
          name === 'price' &&
          (appliedFilters.includes('sterlingPrice_from') ||
            appliedFilters.includes('sterlingPrice_to'));

        if (includesFilter || includesRange || includesPoundSterling) {
          count++;
        }
      });
    }

    /*
     * We count the filter value here since it appears as a path parameter rather than a query
     * and so won't be counted when comparing the filters data to query values
     */
    if (make || makeQueryValue) {
      if (filterValue && filterValue.length > 0) {
        count += 2;
      } else {
        count++;
      }
    }

    /*
     * The filter name for county is location which means it does not match the query value
     * associated with this filter, requiring a manual check
     */
    if (area) {
      count++;
    }
    return count;
  }, [query, filtersData]);

  const initialCount = calculateCount();
  const [count, setCount] = useState(initialCount);

  useMemo(() => {
    setCount(calculateCount());
  }, [calculateCount]);

  return {
    count,
  };
};

export { useFilterCount };
