import { useState } from 'react';
import useSWR from 'swr';

import { donedealGateway } from 'api/api-utils/commonApiUtils';

import { useLastSearch } from 'components/PageWrappers/SharedComponents/LastSearchCard/hooks/useLastSearch';
import { useUserContext } from 'contexts/UserContext';
import { useAuthOptions } from 'hooks/useAuthOptions';

import { setAuthorizationHeader } from 'api/api-utils/authenticate';

import type { SavedSearch } from 'types';

import { rg4js } from 'helpers/raygun';

import type { PreviousSearchesArgs } from 'features/PreviousSearches/PreviousSearches.typed';

const usePreviousSearches = (args: PreviousSearchesArgs) => {
  const { onSearchCallback, page } = args;

  const { status, accessToken, handleLogin } = useAuthOptions();
  const { lastSearchData } = useLastSearch();
  const { user } = useUserContext();

  /*
   * We set this to true when opening the modal and false on close of the modal
   * to avoid fetching when the modal is on screen, or when there is an error.
   *
   * If there is an error, we set this to true only if the user decides to
   * refetch data again.
   *
   * This limits network requests to when the user wishes to see the data in the
   * interest of performance.
   * */
  const [shouldFetch, setShouldFetch] = useState(false);
  const [isPreviousSearchesModalOpen, setIsPreviousSearchesModalOpen] =
    useState(false);

  const showSearch = Boolean(lastSearchData);
  const isEnabled = status === 'authenticated' || showSearch;
  const isLoading = status === 'loading';

  const getSavedSearches = async (
    url: string,
    token: string,
    userId: number,
  ) => {
    const { data } = await donedealGateway.get(url, {
      headers: {
        Authorization: setAuthorizationHeader(token),
        'X-Identity-UserId': userId,
      },
    });

    return data;
  };

  /*
   * If there is a network error, stop fetching.
   *
   * This prevents any error being returned from useSWR.
   */
  const onErrorRetry = (error: any) => {
    setShouldFetch(false);
    rg4js('send', {
      error: new Error('Error fetching saved searches'),
      tags: [page],
      customData: {
        message: error.message || 'client_error',
      },
    });
  };

  const { data: savedSearchesData, isValidating } = useSWR<Array<SavedSearch>>(
    shouldFetch && accessToken && user?.id
      ? [`/ddapi/v1/users/savedsearches?page=0&size=3`, accessToken, user?.id]
      : null,
    getSavedSearches,
    { onErrorRetry },
  );

  const isSavedSearchesLoading = isValidating && status === 'authenticated';
  const savedSearchesError =
    !isValidating &&
    savedSearchesData === undefined &&
    status === 'authenticated';
  const savedSearches =
    savedSearchesData && savedSearchesData.length > 0
      ? savedSearchesData
      : undefined;

  const onClick = () => {
    if (isEnabled) {
      setIsPreviousSearchesModalOpen(true);
    }
    if (status === 'authenticated') {
      setShouldFetch(true);
    }
  };

  const askToClose = () => {
    setShouldFetch(false);
    setIsPreviousSearchesModalOpen(false);
  };

  const onSavedSearchesError = () => setShouldFetch(true);

  const login = () => handleLogin();

  const onSearch = () => {
    askToClose();
    onSearchCallback && onSearchCallback();
  };

  return {
    isLoading,
    isDisabled: !isEnabled,
    onClick,
    isSavedSearchesLoading,
    savedSearches,
    askToClose,
    savedSearchesError,
    onSavedSearchesError,
    isOpen: isPreviousSearchesModalOpen,
    login,
    status,
    onSearch,
    lastSearchData,
  };
};

export { usePreviousSearches };
