import { LinkButton } from 'components/Toolkit/Button/LinkButton';

import { NoSearchesText } from 'features/PreviousSearches/PreviousSearchesModal/components/SubsidiaryText/SubsidiaryText.styled';

type SubsidiaryTextProps = {
  login: () => void;
  status: 'authenticated' | 'loading' | 'unauthenticated';
};

const SubsidiaryText = (props: SubsidiaryTextProps) => {
  const { login, status } = props;

  return (
    <>
      {status === 'authenticated' && (
        <NoSearchesText>Save a search and it will appear here</NoSearchesText>
      )}
      {status === 'unauthenticated' && (
        <LinkButton onClick={login}>
          Log in to see your saved searches
        </LinkButton>
      )}
    </>
  );
};

export { SubsidiaryText };
