import { useEffect, useRef } from 'react';

type UseOnScrollArgs = {
  /**
   * A handler that will be called when the user scrolls down. It will only be called at most once
   * every tick of the event loop which is ~16ms.
   */
  onScrollDown: () => void;
  /**
   * A handler that will be called when the user scrolls up. It will only be called at most once
   * every tick of the event loop which is ~16ms.
   */
  onScrollUp: () => void;
  /**
   * The threshold in pixels that the user must scroll before the `onScrollDown` or `onScrollUp`
   * handlers are called. Defaults to 0 which means handlers will be called immediately on
   * scrolling.
   */
  initialThreshold?: number;
};

const useOnScroll = (args: UseOnScrollArgs) => {
  const { onScrollDown, onScrollUp, initialThreshold = 0 } = args;

  const threshold = Math.max(0, initialThreshold);
  /**
   * Set when the event loop has finished ticking and so should execute callback again
   */
  const shouldExecuteCallback = useRef(true);
  /**
   * Save the previous scroll position in order to compare it with the current scroll position
   * and determine whether onScrollDown or onScrollUp should be called
   */
  const lastScrollY = useRef(0);
  /**
   * Track previously requested animation request by ID
   */
  const animationFrameID = useRef(0);

  const executeScrollCallback = () => {
    const scrollY = window.scrollY;

    if (Math.abs(scrollY - lastScrollY.current) < threshold) {
      shouldExecuteCallback.current = true;
      return;
    }
    if (scrollY > lastScrollY.current) {
      onScrollDown();
    } else {
      onScrollUp();
    }
    lastScrollY.current = scrollY > 0 ? scrollY : 0;
    shouldExecuteCallback.current = true;
  };

  const onScrollYAxis = () => {
    if (shouldExecuteCallback.current) {
      /**
       * Cancel the previous animation frame request in order to
       * avoid making multiple requests for an animation at the same time
       */
      window.cancelAnimationFrame(animationFrameID.current);
      animationFrameID.current = window.requestAnimationFrame(
        executeScrollCallback,
      );
      shouldExecuteCallback.current = false;
    }
  };

  useEffect(() => {
    lastScrollY.current = window.scrollY;

    window.addEventListener('scroll', onScrollYAxis, { passive: true });

    return () => {
      window.removeEventListener('scroll', onScrollYAxis);
      /**
       * If the hook is unmounted before the animation frame is executed,
       * cancel the animation request
       */
      window.cancelAnimationFrame(animationFrameID.current);
    };
    // TODO Clean up this effect's dependencies. We're disabling this lint error for now so we can
    // clean up the lint logs. Ideally we should rewrite this code to be less error prone and trust
    // the lint rule's judgement.
    // https://distilledsch.tpondemand.com/RestUI/Board.aspx#page=userstory/98606
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export { useOnScroll };
