import { MouseEvent, useState } from 'react';
import type { SaveAdProps } from 'components/AdCard/SaveAd/SaveAd.typed';
import * as Styled from 'components/AdCard/SaveAd/SaveAd.styled';

const SaveAd = (props: SaveAdProps) => {
  const {
    isAdSaved,
    toggleSave,
    variant = 'DEFAULT',
    iconSizeDesktop,
    className,
    isSaving = false,
  } = props;

  const iconDimensionDesktop = iconSizeDesktop === 'SM' ? 20 : 24;
  const [isAnimating, setIsAnimating] = useState(false);

  function handleClick(event: MouseEvent<HTMLButtonElement>) {
    isAdSaved ? setIsAnimating(false) : setIsAnimating(true);
    toggleSave && toggleSave(event);
  }

  function handleAnimationEnd() {
    setIsAnimating(false);
  }

  return (
    <Styled.Action
      onClick={handleClick}
      data-testid="save-ad"
      data-tracking="ad-save-this-ad"
      className={className}
    >
      <Styled.SHeartOutlineIc
        data-testid="save"
        $iconDimensionDesktop={iconDimensionDesktop}
        $isAdSaved={isAdSaved}
        $variant={variant}
        $isAnimating={isAnimating}
        onAnimationEnd={handleAnimationEnd}
        $isSaving={isSaving}
      />
    </Styled.Action>
  );
};

export { SaveAd };
