import { createContext, ReactNode, useContext, useMemo } from 'react';
import { MakeModelItem } from 'features/filters/components/MakeModel/MakeModel.typed';
import { useMakeModel } from 'components/SearchPage/features/QuickFilters/components/MakeModel/MakeModel.hook';

type MakeModelProviderProps = { children: ReactNode };

type MakeModelsContextData = {
  setMakeModelState: (makeModel: MakeModelItem[]) => void;
  makeModelState: MakeModelItem[];
};

const MakeModelsContext = createContext<MakeModelsContextData>({
  makeModelState: [],
  setMakeModelState: () => [],
});

const MakeModelProvider = (props: MakeModelProviderProps) => {
  const { children } = props;
  const { makeModelState, setMakeModelState } = useMakeModel();

  const value = useMemo(
    () => ({
      makeModelState,
      setMakeModelState,
    }),
    // TODO Clean up this memo's dependencies. We're disabling this lint error for now so we can
    // clean up the lint logs. Ideally we should rewrite this code to be less error prone and trust
    // the lint rule's judgement.
    // https://distilledsch.tpondemand.com/RestUI/Board.aspx#page=userstory/98606
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [makeModelState],
  );

  return (
    <MakeModelsContext.Provider value={value}>
      {children}
    </MakeModelsContext.Provider>
  );
};

const useMakeModelContext = () => useContext(MakeModelsContext);

export { MakeModelProvider, useMakeModelContext };
