import type { BrandingStripProps } from 'components/ToolkitV2/CardV2/components/BrandingStrip/BrandingStrip.typed';
import * as Styled from 'components/ToolkitV2/CardV2/components/BrandingStrip/BrandingStrip.styled';

const BrandingStrip = (props: BrandingStripProps) => {
  const { trusted, type, rating, name, logo } = props;
  const subtitle = (
    <>
      {trusted && (
        <Styled.TrustedDealerBadge key={'shield'} data-testid="shield-icon" />
      )}
      <Styled.DealerType>
        {trusted && 'Trusted '}
        {type}
      </Styled.DealerType>
      {rating && <Styled.Star data-testid="star-icon" />}
      {rating}
    </>
  );
  return (
    <Styled.Container>
      {logo && <Styled.Logo src={logo} alt={name} />}
      <Styled.TitleWrapper>
        <Styled.Title>{name}</Styled.Title>
        <Styled.SubTitle>{subtitle}</Styled.SubTitle>
      </Styled.TitleWrapper>
    </Styled.Container>
  );
};

export { BrandingStrip };
