import type { ReactNode } from 'react';

const POSITION = {
  ABOVE: 'ABOVE',
  BELOW: 'BELOW',
} as const;

type Positions = (typeof POSITION)[keyof typeof POSITION];

type TooltipProps = {
  isOpen: boolean;
  children: ReactNode;
  headerText: string;
  copy: string;
  askToClose?: () => void;
  position?: Positions;
  className?: string;
  dataTrackingAttribute?: string;
};

export { POSITION };
export type { TooltipProps, Positions };
