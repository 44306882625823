import * as Styled from 'components/SearchPage/components/Listings/components/SearchCardV2/SearchCardV2.styled';
import type { SearchCardV2Props } from 'components/SearchPage/components/Listings/components/SearchCardV2/SearchCardV2.typed';
import { Card } from 'components/ToolkitV2/CardV2/Card';
import { ORIENTATION } from 'components/ToolkitV2/CardV2/Card.typed';
import { BrandingStrip } from 'components/ToolkitV2/CardV2/components/BrandingStrip/BrandingStrip';
import { Highlight } from 'components/ToolkitV2/CardV2/components/Highlight/Highlight';
import { Gallery } from 'components/AdCard/Gallery/Gallery';
import Price from 'components/AdCard/Price/Price';
import { SaveAd } from 'components/AdCard/SaveAd/SaveAd';
import { useSaveAd } from 'components/Support/UseSaveAd/useSaveAd';
import { useUserContext } from 'contexts/UserContext';
import { useAuthOptions } from 'hooks/useAuthOptions';
import { formatAuthorizationHeader } from 'helpers/auth';
import { WithImageOverlay } from 'components/ToolkitV2/CardV2/enhancements/WithImageOverlay/WithImageOverlay';

const SearchCardV2 = (props: SearchCardV2Props) => {
  const {
    ad,
    isGrid = false,
    currentSection,
    createSavedAdEvent,
    deleteSavedAdEvent,
  } = props;
  const {
    gallery,
    dealer,
    relativeUrl,
    friendlyUrl,
    spotlighted,
    title,
    tags,
    imageAlt,
    metaInfo,
    age,
    county,
    countyTown,
    section,
  } = ad;

  const { previousPrice, price, financePPM, priceOnRequest } = ad.priceInfo;
  const listingUrl = relativeUrl ?? friendlyUrl;

  const orientationConfig = {
    orientationSmall: ORIENTATION.VERTICAL,
    orientationMedium: isGrid ? ORIENTATION.VERTICAL : ORIENTATION.HORIZONTAL,
    orientationLarge: isGrid ? ORIENTATION.VERTICAL : ORIENTATION.HORIZONTAL,
  };

  const getLocationText = () => {
    const locationText =
      countyTown && county ? `${countyTown}, ${county}` : county;
    const countyOrCountyTownText = isGrid && county ? county : locationText;
    return countyOrCountyTownText ? [countyOrCountyTownText] : [];
  };

  const metaData: string[] = [
    ...(!isGrid && metaInfo ? metaInfo : []),
    age,
    ...getLocationText(),
    ...(currentSection !== section.name ? [section.displayName] : []),
  ];

  const { user } = useUserContext();
  const { accessToken, handleLogin } = useAuthOptions();
  const authorisationHeader = formatAuthorizationHeader(accessToken);

  const { onSave, isAdSaved, isSaving } = useSaveAd({
    adId: ad.id,
    userSaved: ad.saved,
    section: ad.section.name,
    sellerType: dealer ? 'DEALER' : 'PRIVATE',
    sectionName: ad.section.name,
    userId: user?.id,
    trackCreateSaveAd: createSavedAdEvent,
    trackDeleteSaveAd: deleteSavedAdEvent,
    authorisationHeader,
    handleLogin,
  });

  return (
    // We chose not to use Next Link here as explained in this PR:
    // https://github.com/distilledmedia/dsch-donedeal-frontend/pull/2183
    <a href={listingUrl}>
      <Styled.Wrapper isGrid={isGrid}>
        {!isGrid && dealer && (
          <BrandingStrip
            {...dealer}
            logo={dealer.logo?.small}
            isGrid={isGrid}
          />
        )}
        {spotlighted && <Highlight color="GREY_DARKER">Spotlight</Highlight>}
        <Card
          {...orientationConfig}
          image={
            <WithImageOverlay
              topRightSlot={
                <Styled.ImageSavedAd isGrid={isGrid}>
                  <SaveAd
                    toggleSave={onSave}
                    isAdSaved={isAdSaved}
                    hideText
                    iconSizeDesktop="MD"
                    isSaving={isSaving}
                  />
                </Styled.ImageSavedAd>
              }
            >
              <Gallery
                coverImage={gallery?.coverImage.small2}
                thumbnails={gallery?.thumbnails}
                totalMediaCount={gallery?.totalMediaCount}
                isSpotlight={ad.spotlighted}
                isGrid={isGrid}
                title={title}
                imageAlt={imageAlt ?? 'no-image'}
              />
            </WithImageOverlay>
          }
          header={
            <Styled.HeaderContainer>
              <Styled.Header isGrid={isGrid}>
                <Styled.Title
                  lines={2}
                  isGrid={isGrid}
                  fontSizes={{
                    sm: isGrid ? 'M14' : 'L18',
                  }}
                  forwardedAs={'p'}
                >
                  {title}
                </Styled.Title>
                <Styled.MetaInfo lines={2} forwardedAs={'ul'}>
                  {metaData &&
                    metaData.map((info, index) => (
                      <Styled.MetaInfoItem
                        key={index}
                        fontSizes={{
                          sm: isGrid ? 'B14' : 'M14',
                        }}
                      >
                        {info}
                      </Styled.MetaInfoItem>
                    ))}
                </Styled.MetaInfo>
              </Styled.Header>
              {!isGrid && tags?.length && (
                <Styled.TagsList
                  singleLine={dealer?.singleLineTag ?? true}
                  tags={tags}
                />
              )}
            </Styled.HeaderContainer>
          }
          footer={
            <>
              <Price
                previousPrice={previousPrice}
                price={price}
                financePPM={financePPM}
                priceOnRequest={priceOnRequest}
                isGrid={isGrid}
              />
              {!isGrid && (
                <Styled.BodySavedAd isGrid={isGrid}>
                  <SaveAd
                    toggleSave={onSave}
                    isAdSaved={isAdSaved}
                    hideText
                    iconSizeDesktop="MD"
                    isSaving={isSaving}
                  />
                </Styled.BodySavedAd>
              )}
            </>
          }
          gapSmall={isGrid ? 'S4' : 'M16'}
          gapMedium={isGrid ? 'S8' : undefined}
          paddingSmall={isGrid ? 'S4' : 'M16'}
          paddingMedium={isGrid ? 'S8' : 'M24'}
        />
      </Styled.Wrapper>
    </a>
  );
};

export { SearchCardV2 };
