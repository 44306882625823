import { SavedSearch, ResponseMakeModelType } from 'types';
import {
  IGetAdsRequestFilter,
  IGetAdsRequestRange,
} from 'api/types/searchPageApiTypes';
import { groupModelsByMake } from 'features/filters/Filters.mapper';
import { getFiltersUrl } from 'features/filters/Filters.helper';

const mapMakeModelFilters = (
  makeModelSavedSearchFilters?: ResponseMakeModelType,
) => {
  if (!makeModelSavedSearchFilters) return [];
  const modelsByMake = makeModelSavedSearchFilters
    ? groupModelsByMake(makeModelSavedSearchFilters)
    : [];

  return Object.keys(modelsByMake).map((item) => ({
    makeValue: item,
    modelValue: modelsByMake[item] ? [...modelsByMake[item]] : [],
  }));
};

export const getSavedSearchUrl = (savedSearch: SavedSearch) => {
  const {
    sections,
    sort,
    terms,
    makeModelFilters,
    ranges,
    filters,
    geoFilter,
  } = savedSearch.search;

  const makeModelFiltersSearch = makeModelFilters as {
    make: string;
    model: string;
    trim: string;
  }[];

  if (sections) {
    const url = getFiltersUrl(
      sections[0],
      {
        ...(sort && {
          sort: sort.toLowerCase(),
        }),
        ...(terms && {
          words: terms,
        }),
      },
      filters as IGetAdsRequestFilter[],
      mapMakeModelFilters(makeModelFiltersSearch),
      ranges as IGetAdsRequestRange[],
      geoFilter?.countyTown,
      geoFilter?.rad.toString(),
      geoFilter?.county,
    );
    return `${url}&saved`;
  }
};
