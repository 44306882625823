import type { Thumbnails } from 'api/types/searchPageApiTypes';

const buildThumbnailsList = (thumbnails: Thumbnails[]): Thumbnails[] => {
  const video = thumbnails.find((t) => t.type === 'video');
  const threeSixty = thumbnails.find((t) => t.type === '360');
  const [firstImage, secondImage, thirdImage] = thumbnails.filter(
    (t) => t.type === 'photo',
  );

  if (video && threeSixty) return [video, threeSixty, firstImage];
  if (video) return [video, firstImage, secondImage];
  if (threeSixty) return [threeSixty, firstImage, secondImage];
  return [firstImage, secondImage, thirdImage].filter(Boolean);
};

export { buildThumbnailsList };
