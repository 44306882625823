import { useEffect } from 'react';
import { useTheme } from 'styled-components';
import { ShieldCheckmark2Ic } from '@dsch/dd-icons';

import { Button } from 'components/Toolkit/Button/Button';
import * as Styled from 'components/SearchPage/TrustedDealerModal/TrustedDealerModal.styled';
import { cookies } from 'constants/cookies';
import { useCookies } from 'hooks/UseCookies';
import type { TrustedDealerModalProps } from 'components/SearchPage/TrustedDealerModal/TrustedDealerModal.typed';

const criteriaList = [
  {
    title: 'Excellent Customer Service',
    subtitle: 'Google Reviews rating exceeding 3.5/5 stars',
  },
  {
    title: 'Established Business',
    subtitle: 'In business for more than 1 year',
  },
  {
    title: 'Professional Ad Presentation',
    subtitle: 'Photos and pricing are displayed on the majority of ads',
  },
];

function TrustedDealerModal(props: TrustedDealerModalProps) {
  const { isOpen, askToClose } = props;
  const { get: hasUserSeenTdpModalCookie, set: setHasUserSeenTdpModalCookie } =
    useCookies({ name: cookies.TDP_MODAL_SEEN });
  const theme = useTheme();

  useEffect(() => {
    if (!hasUserSeenTdpModalCookie()) {
      setHasUserSeenTdpModalCookie({
        value: 'true',
        options: {
          path: '/',
          maxAge: 15780000,
        },
      });
    }
    // TODO Clean up this effect's dependencies. We're disabling this lint error for now so we can
    // clean up the lint logs. Ideally we should rewrite this code to be less error prone and trust
    // the lint rule's judgement.
    // https://distilledsch.tpondemand.com/RestUI/Board.aspx#page=userstory/98606
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Styled.SBaseModal
      isOpen={isOpen}
      askToClose={askToClose}
      showCloseIcon={false}
      testId="tdp-modal"
    >
      <Styled.IntroText>Introducing</Styled.IntroText>
      <Styled.Header
        title={<Styled.HeaderText>Trusted Dealer</Styled.HeaderText>}
        icon={
          <ShieldCheckmark2Ic
            width={32}
            height={32}
            color={theme.colors.GREEN}
          />
        }
      />
      <Styled.OpeningText>
        Our Trusted Dealer Programme celebrates exceptional agri dealerships who
        meet these criteria:
      </Styled.OpeningText>
      <Styled.BodyText>
        {criteriaList.map((criteria, index) => {
          return (
            <Styled.SInfoListItem
              key={index}
              icon={<Styled.SIoCheckmark />}
              title={criteria.title}
              body={criteria.subtitle}
            />
          );
        })}
      </Styled.BodyText>
      <Styled.ClosingText>
        So you know you can buy with confidence.
      </Styled.ClosingText>
      <Styled.ButtonContainer>
        <Button
          onClick={askToClose}
          data-tracking="tdp-close-btn"
          data-testid="tdp-close-btn"
        >
          Okay, got it!
        </Button>
      </Styled.ButtonContainer>
    </Styled.SBaseModal>
  );
}

export { TrustedDealerModal };
