import styled from 'styled-components';
import Modal from 'react-modal';

import { ChevronBackOutlineIc, CloseOutlineIc } from '@dsch/dd-icons';

import { LinkButton } from 'components/Toolkit/Button/LinkButton';

import { media } from 'helpers/breakpoints';

const StyledPreviousSearchesModal = styled(Modal)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 0;
  padding: 0;
  background-color: ${({ theme }) => theme.colors.WHITE};
  z-index: ${({ theme }) => theme.zIndices.MODAL};
  outline: none;

  transform: translateX(100%);
  transition: all 0.2s ease-in-out;

  .ReactModal__Overlay--after-open & {
    transform: translateX(0);
  }
  .ReactModal__Overlay--before-close & {
    transform: translateX(100%);
  }

  ${media.large} {
    width: 360px;
    max-height: 516px;
    height: auto;
    left: calc(50% - 180px);
    top: calc(50% - 258px);
    border-radius: ${({ theme }) => theme.borderRadius.default};

    transform: translateY(100%);
    transition: all 0.2s ease-out;
    opacity: 0;

    .ReactModal__Overlay--after-open & {
      transform: translateY(0);
      opacity: 1;
    }
    .ReactModal__Overlay--before-close & {
      transform: translateY(100%);
      opacity: 0;
    }
  }
`;

const Content = styled.div`
  padding: ${({ theme }) => theme.spacing.M16};

  ${media.large} {
    padding: ${({ theme }) => theme.spacing.M24};
  }
`;

const BackIcon = styled(ChevronBackOutlineIc)`
  color: ${({ theme }) => theme.colors.BLUE};
`;

const BackButton = styled(LinkButton)`
  display: flex !important;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.M16};

  ${media.large} {
    display: none !important;
  }

  &:hover {
    ${BackIcon} {
      color: ${({ theme }) => theme.colors.BLUE_DARKER};
    }
  }
`;

const Heading = styled.p`
  ${({ theme }) => theme.fontSize.L18};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.GREY_DARKER};
  display: flex;
  justify-content: space-between;
`;

const CloseIcon = styled(CloseOutlineIc)`
  display: none;

  ${media.large} {
    display: block;
    color: ${({ theme }) => theme.colors.GREY_DARKER};
    cursor: pointer;
  }
`;

export {
  StyledPreviousSearchesModal,
  Content,
  Heading,
  BackButton,
  CloseIcon,
  BackIcon,
};
